

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, AWS_S3_BASE_URL } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import suneditor from "suneditor";
import dialog from "suneditor/src/plugins/modules/dialog";
import plugins, { imageGallery } from "suneditor/src/plugins";
import { LOAN_PURPOSE } from "../../../constants/constants";

@Component
export default class EditTemplate extends Vue {
  public temData: any = {};
  public allImages: any = [];
  public subject = null;
  public editor: any = null;
  public loanPurpose = LOAN_PURPOSE;
  public templateHeading = false;
  public emailExist = false;
  public emailData: any = "";

  public async saveTemplate() {
    try {
      console.log(this.emailExist, 'dsds')
      if(this.emailExist) {
       this.$snotify.info("Id already exist");
        return
        }
        
      this.temData.template = await this.editor.getContents();
      // data.template = contents;
      const response = await Axios.post(
        BASE_API_URL + "super-admin/email-template/saveTemplate",
        this.temData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success("Template Details has been updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedTemplate() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/email-template/getSelectedTemplate/${this.$route.query._id}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.temData = response.data;

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getId() {
     try {
      const response = await Axios.post(
        BASE_API_URL + "super-admin/email-template/getId",
       {
        id: this.temData.id},
        { headers: authHeader() }
      );
      this.emailExist = response.data
    
    } catch (error) {
      console.log(error);
    }
  }

  public async sendTestMail() {
    try {
      const data = { emailData: this.emailData, id: this.temData.id };

      const response = await Axios.post(BASE_API_URL + "test-email/sendTestMail", data, {
        headers: authHeader(),
      });
      if (response.status === 201) {
        this.$modal.hide('testEmail')
        this.$snotify.success("Email sent Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if (this.$route.query._id) {
      const templateData = await this.getSelectedTemplate();
      this.templateHeading = !this.templateHeading;
    }

    this.editor = await suneditor.create("pageEditor", {
      plugins: {
        ...plugins,
      },
      width: "auto",
      height: "500px",
      buttonList: [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic"],
        ["fontColor", "hiliteColor", "textStyle"],
        ["removeFormat"],
        // Line break
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "lineHeight"],
        ["table", "link", "image", "video" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".

        // ["fullScreen", "showBlocks", "codeView"],
        // ["preview", "print"],
        // ["template"] /* ["imageGallery"], */,
      ],
      imageWidth: "250px",
      imageHeight: "150px",
      videoHeightShow: true,
      videoHeight: "30%",
      videoWidth: "30%",
      videoFileInput: true,
      imageUploadHeader: authHeader(),
      imageUploadUrl: `${BASE_API_URL}super-admin/email-template/uploadImageForTemplete/${this.$route.query._id}`,
      videoUploadHeader: authHeader(),
      videoUploadUrl: `${BASE_API_URL}super-admin/email-template/uploadImageForTemplete/${this.$route.query._id}`,
    });
  }
}
